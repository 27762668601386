import React from "react"
import { Link } from "gatsby"
import Container from "../components/container"
import { injectIntl } from "gatsby-plugin-intl"

const Footer = ({ intl }) => {
  return (
    <div className="blue-gradient text-white">
      <Container>
        <div className="py-4 text-center">
          <Link
            to={"/mentions-legales/"}
            title="Mentions légales"
            className="text-white"
          >
            {intl.formatMessage({ id: "footer.link-1" })}
          </Link>{" "}
          -{" "}
          <a
            href="https://www.conseil-national.medecin.fr/annuaire"
            title="Annuaire des médecins"
            target="_blank"
            rel="noreferrer"
            className="text-white"
          >
            {intl.formatMessage({ id: "footer.link-2" })} -{" "}
          </a>
          <a
            href="https://www.conseil-national.medecin.fr/"
            title="CNOM"
            target="_blank"
            rel="noreferrer"
            className="text-white"
          >
            {intl.formatMessage({ id: "footer.link-3" })}
          </a>
        </div>
      </Container>
    </div>
  )
}

export default injectIntl(Footer)
