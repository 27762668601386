import React from "react"
import { Link } from "gatsby"
import { injectIntl } from "gatsby-plugin-intl"

const Header = ({ props, intl }) => {
  const [navbarOpen, setNavbarOpen] = React.useState(false)
  return (
    <nav className="relative flex flex-wrap items-center justify-between px-2 py-3 navbar-expand-lg">
      <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
        <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
          <Link
            className="text-xs md:text-lg font-bold font-title leading-relaxed inline-block py-2 whitespace-no-wrap uppercase text-black"
            to={intl.formatMessage({ id: "header.menu.accueil.link" })}
            activeClassName="active"
          >
            {intl.formatMessage({ id: "header.title" })}
          </Link>
          <button
            className="text-black cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
            type="button"
            onClick={() => setNavbarOpen(!navbarOpen)}
          >
            <svg
              className="fill-current h-3 w-3"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>{intl.formatMessage({ id: "header.menu.title" })}</title>
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
            </svg>
          </button>
        </div>
        <div
          className={
            "lg:flex flex-grow items-center" +
            (navbarOpen ? " flex" : " hidden")
          }
          id="example-navbar-danger"
        >
          <ul className="menu flex flex-col lg:flex-row list-none lg:ml-auto">
            <li className="nav-item">
              <Link
                className="px-3 py-2 block leading-snug text-black hover:opacity-75"
                to={intl.formatMessage({ id: "header.menu.accueil.link" })}
                activeClassName="active"
              >
                {intl.formatMessage({ id: "header.menu.accueil.text" })}
              </Link>
            </li>
            <li className="nav-item">
              <Link
                activeClassName="active"
                className="block px-3 py-2 leading-snug text-black hover:opacity-75"
                to={intl.formatMessage({ id: "header.menu.le-cabinet.link" })}
                >
                {intl.formatMessage({ id: "header.menu.le-cabinet.text" })}
              </Link>
            </li>
            <li className="nav-item">
              <Link
                activeClassName="active"
                className="px-3 py-2 block leading-snug text-black hover:opacity-75"
                to={intl.formatMessage({ id: "header.menu.infos-utiles.link" })}
              >
                {intl.formatMessage({ id: "header.menu.infos-utiles.text" })}
              </Link>
            </li>
            <li className="nav-item">
              <Link
                activeClassName="active"
                className="px-3 py-2 block leading-snug text-black hover:opacity-75"
                to={intl.formatMessage({ id: "header.menu.blog.link" })}
              >
                {intl.formatMessage({ id: "header.menu.blog.text" })}
              </Link>
            </li>
            <li className="nav-item flex">
              <Link
                className="pl-3 py-2 block leading-snug text-black hover:opacity-75"
                to="/"
              >FR
              </Link>
              <span className="py-2 px-1">/</span>
              <Link
                className="py-2 block leading-snug text-black hover:opacity-75"
                to="/en/"
              >EN
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}

export default injectIntl(Header)
