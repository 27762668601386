import React from "react"

export default function Container({ classes, children }) {
  const otherClasses = classes ? classes : ""
  return (
    <div className={`px-4 md:px-0 container mx-auto leading-8 ${otherClasses}`}>
      {children}
    </div>
  )
}
